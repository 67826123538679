module.exports = {
  pathPrefix: '',
  siteUrl: 'https://brycejunkins.com',
  siteTitle: 'Bryce Junkins',
  siteDescription: 'A software engineer',
  author: 'Bryce Junkins',
  postsForArchivePage: 3,
  defaultLanguage: 'en',
  disqusScript: process.env.DISQUS_SCRIPT || 'https://rolwinreevan.disqus.com/embed.js',
  pages: {
    home: '/',
    blog: 'blog',
    contact: 'contact',
    resume: 'resume',
  },
  social: {
    github: 'https://github.com/bajunkins?tab=repositories',
    instagram: 'https://www.instagram.com/brycejunkins/',
    linkedin: 'https://www.linkedin.com/in/bajunkins/',
    mail: 'mailto:brycejunkinz@gmail.com',
    rss: '/rss.xml',
  },
  contactFormUrl: process.env.CONTACT_FORM_ENDPOINT || 'https://getform.io/f/09a3066f-c638-40db-ad59-05e4ed71e451',
};
